import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

export enum CreateFromContentTreeCohorts {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	TEST = 'test',
}

export const CREATE_FROM_CONTENT_TREE_EXPERIMENT = 'confluence_create_from_content_tree_experiment';

export const useCreateFromContentTreeEligible = () => {
	const [expConfig] = UNSAFE_noExposureExp('confluence_create_from_content_tree_experiment');
	const cohort = expConfig.get(
		'cohort',
		CreateFromContentTreeCohorts.NOT_ENROLLED,
	) as CreateFromContentTreeCohorts;

	const isCreateFromContentTreeEnrolled = cohort !== CreateFromContentTreeCohorts.NOT_ENROLLED;

	const isCreateFromContentTreeEligible = cohort === CreateFromContentTreeCohorts.TEST;

	return {
		cohort,
		isCreateFromContentTreeEnrolled,
		isCreateFromContentTreeEligible,
	};
};
